import React from "react"
import { graphql } from "gatsby"
import Markdown from "react-markdown"
import Img from "gatsby-image"
import Layout from "../components/common/layout"
import FadeInAnimation from "../components/animations/fade"
import { LinkRenderer, HeadingRenderer } from "../utilities/markdownUtils"
import storiaStyles from "../styles/pages/storia.module.scss"
import markdownStyles from "../styles/common/markdown.module.scss"

const StoriaSantuario = ({ data, location }) => {
  const storia = data.strapiStoriaDelSantuario || {}

  return (
    <Layout
      pageMeta={{
        title: "Storia del Santuario",
        keywords: [
          "lettera apostolica",
          "commenti alle letture",
          "testimonianze di fede cristiana",
        ],
        description: "Storia del Santuario del SS Crocifisso di Boca ",
        link: "/storia-del-santuario",
      }}
      location={location}
      cssClass={storiaStyles.pageWrapper}
    >
      <h2 className={storiaStyles.title}>
        <FadeInAnimation direction="right">
          {data.strapiStoriaDelSantuario.titolo}
        </FadeInAnimation>
      </h2>
      <article className={storiaStyles.selected}>
        <FadeInAnimation direction="up">
          {storia.multimedia.map((item) => (
            <>
              {item.testo && (
                <Markdown
                  key={item.id}
                  className={markdownStyles.testo}
                  renderers={{ heading: HeadingRenderer, link: LinkRenderer }}
                  source={item.testo}
                />
              )}
            </>
          ))}
        </FadeInAnimation>
      </article>
      <article className={storiaStyles.foto}>
        <FadeInAnimation direction="left">
          <div className={storiaStyles.fotoGrid}>
            {storia.multimedia.map((item) => (
              <>
                {item.file &&
                  item.file.ext !== ".mp3" &&
                  item.file.ext !== ".pdf" && (
                    <Img
                      key={item.file.id}
                      className={markdownStyles.image}
                      fluid={item.file.childImageSharp.fluid}
                      alt={item.publicURL}
                    />
                  )}
              </>
            ))}
          </div>
        </FadeInAnimation>
      </article>
    </Layout>
  )
}

export default StoriaSantuario

export const query = graphql`
  query StoriaSantuarioQuery {
    strapiStoriaDelSantuario {
      titolo
      multimedia {
        id
        testo
        file {
          id
          ext
          publicURL
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
